import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
  searchTerm: "",
  filterWarehouse: "1",
  filterDate: "",
  selectedItem: [],
  tempItems: [],
  precision: {
    1: 0.1,
    2: 0.01,
    3: 0.001,
    4: 0.0001,
  },
};

const itemSlice = createSlice({
  name: "items",
  initialState,
  reducers: {
    setSearchTerm: (state, action) => {
      state.searchTerm = action.payload;
    },
    setFilterWarehouse: (state, action) => {
      state.filterWarehouse = action.payload;
    },
    setFilterDate: (state, action) => {
      state.filterDate = action.payload;
    },
    clearSelectedItems: (state) => {
      state.selectedItem = [];
    },
    setSelectedItems: (state, action) => {
      //console.log("Before updating selectedItem:", state.selectedItem);
      //console.log("Before updating tempItems:", state.tempItems);

      const dataMap = new Map();

      // Masukkan data dari selectedItem ke dalam Map
      state.selectedItem.forEach(item => {
        if (item?.itemData?.id) {
          dataMap.set(item.itemData.id, item);
        }
      });

      // Cek data baru dari tempItems, hindari duplikat
      const duplicates = [];
      state.tempItems.forEach(item => {
        if (item?.itemData?.id) {
          if (!dataMap.has(item.itemData.id)) {
            dataMap.set(item.itemData.id, item);
          } else {
            duplicates.push(item);
          }
        }
      });

      //console.log("DataMap:", dataMap);

      // Tampilkan alert jika ada item duplikat
      if (duplicates.length > 0 && !action.payload) {
        alert(`Duplicate items found:\n\n${duplicates.map(item => item.itemData.name).join("\n")}\n\nDuplicate items will be skipped.`);
      }

      // Cek apakah terjadi perubahan pada state sebelum mengubahnya
      //console.log("After processing selectedItem:", Array.from(dataMap.values()));

      // Perbaikan utama: Jangan buat salinan manual, biarkan Redux Toolkit menangani state
      state.selectedItem = Array.from(dataMap.values());

      //console.log("After updating state.selectedItem:", state.selectedItem);
    },
    setTempItems: (state, action) => {
      state.tempItems = action.payload.map((item) => {
        let _item = item.item?item.item:item

        if (_item.stock === undefined) {
          _item.stock = item.stock;
        }

        if (_item.uoms === undefined) {
          _item.uoms = _item.conversions.map(item => item.uom);
        }

        return {
          itemData: _item,
          input: {
            count_quantity: item.count_quantity?item.count_quantity:0,
            book_quantity: item.book_quantity?item.book_quantity:0,
            uom_quantity: item.uom_quantity?item.uom_quantity:0,
            uom: item.uom,
            description: item.description ? item.description : "",
          },
        };
      });
    },
    removeItem: (state, action) => {
      const items = [...state.selectedItem];
      items.splice(action.payload, 1);

      state.selectedItem = items;
    },
    updateInputItemData: (state, action) => {
      const { index, fieldName, value } = action.payload;

      const newData = [...state.selectedItem];

      newData[index].input[fieldName] = value;
      
      state.selectedItem = newData;
    },
    resetModal: (state) => {
      state.tempItems = [];
      state.searchTerm = "";
    },
  },
});

export const {
  setSearchTerm,
  setFilterWarehouse,
  setFilterDate,
  setItemList,
  setTempItems,
  resetModal,
  clearSelectedItems,
  setSelectedItems,
  updateInputItemData,
  removeItem,
} = itemSlice.actions;

export default itemSlice.reducer;
